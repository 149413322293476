import React from "react"
import Typist from 'react-typist';
import SEO from "../components/seo"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { FaSoundcloud, FaGithub, FaLinkedin } from "react-icons/fa";
import { Terminal, TerminalCenter } from "../components/terminal"
import SiteHeader from "../components/siteHeader"
import Footer from "../components/footer"

const WipeDown = styled.div`
    height: ${props => props.hide ? 0 : `12em` };
    transition: height 3s linear;
    overflow: hidden;

    @media screen and (max-width: 500px) {
        height: ${props => props.hide ? 0 : `12em` };
    }
`;

const SocialButtons = styled.ul`
    list-style-type: none;
    display: flex;
    margin: 0 auto;
    padding: 2rem 0;
    max-width: 30rem;
    flex-wrap: wrap;
    justify-content: center;

    li {
        margin: 0;
    }

    a {
        margin: 0 1rem;
        display: block;
        transition: opacity 1s ease;
        opacity: 0.8;
        font-size: 4rem;
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const hideOnMobile = css`
    @media screen and (max-width: 700px) {
        display: none;
    }
`;

const mobileBlurb = css`
    display: none;
    font-family: monospace;

    font-family: "Fira Code", monospace;
    font-weight: 300;
    font-size: 0.9em;

    padding-bottom: 2em;

    h1 {
        font-family: "Fira Code", monospace;
        font-weight: 300;
    }

    @media screen and (max-width: 700px) {
        display: block;
    }
`;


export default class Index extends React.Component {

    state = { socialHidden: true, secondHidden: true, time: new Date() };

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: new Date() }), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    typingDone = () => {
        this.setState({ socialHidden: false });
        setTimeout(this.socialDone, 3000);
    }

    socialDone = () => {
        this.setState({ secondHidden: false });
    }

    render = () => (
        <>

        <SEO title="Home" />
        <Terminal>
            <SiteHeader darkMode={true} />
            <TerminalCenter>
                {/* <noscript>
                $ whoami<br />
                <br />
                Hi, my name is Chris!<br />
                I like to write <a href="https://github.com/neon64" target="_blank" rel="noopener noreferrer">code</a> and think about programming languages<br />
                I am currently studying comp sci and mathematics at Unimelb.<br />
                I also like to write and perform music.<br />
                </noscript> */}
                <pre css={hideOnMobile}><Typist cursor={{ show: true, blink: true, element: '|', hideWhenDone: true, hideWhenDoneDelay: 5000 }} avgTypingDelay={30} onTypingDone={this.typingDone}>
                <h1>Hi, <Typist.Delay ms={100} />my name is Chris<Typist.Delay ms={100} /></h1>
                I like to write <a href="https://github.com/neon64" target="_blank" rel="noopener noreferrer">code</a>, and think about programming language design.<Typist.Delay ms={100} /><br /><br />
                I study computer science and mathematics at Unimelb.<Typist.Delay ms={100} /><br /><br />
                In my spare time I write and perform acoustic &amp; electronic music.</Typist>
                </pre>
                <div css={mobileBlurb}>
                <h1>Hi, my name is Chris</h1>
                I like to write <a href="https://github.com/neon64" target="_blank" rel="noopener noreferrer">code</a>, and think about programming language design.<br /><br />
                I study computer science and mathematics at Unimelb.<br /><br />
                In my spare time I write and perform acoustic &amp; electronic music.
                </div>
                <noscript>
                <pre>
                <h1>Hi, my name is Chris</h1>
                I like to write <a href="https://github.com/neon64" target="_blank" rel="noopener noreferrer">code</a>, and think about programming language design.<br /><br />
                I study computer science and mathematics at Unimelb.<br /><br />
                In my spare time I write and perform acoustic &amp; electronic music.
                </pre>
                </noscript>

                <WipeDown hide={this.state.socialHidden}>
                <SocialButtons>
                    <li><a href="https://www.linkedin.com/in/chris-j-chamberlain" target="_blank" rel="noopener noreferrer"><FaLinkedin aria-label="Add me on LinkedIn"></FaLinkedin></a></li>
                    <li><a href="https://github.com/neon64" target="_blank" rel="noopener noreferrer"><FaGithub  aria-label="View my GitHub"></FaGithub></a></li>
                    <li><a href="https://soundcloud.com/chris-chamberlain-music" target="_blank" rel="noopener noreferrer"><FaSoundcloud aria-label="Listen to my SoundCloud"></FaSoundcloud></a></li>
                </SocialButtons>
                </WipeDown>
                { this.state.secondHidden ? <></> : <pre css={hideOnMobile}><Typist cursor={{ show: true, blink: true, element: '|', hideWhenDone: true, hideWhenDoneDelay: 1000 }} avgTypingDelay={30}>
                    &gt; Todo: write blog posts
                    </Typist></pre>
                }
                <div css={mobileBlurb}>
                    &gt; Todo: write blog posts
                </div>
                {/* <noscript>
                    <pre><SocialButtons hide={this.state.socialHidden}>
                        <li><a href="https://www.linkedin.com/in/chris-j-chamberlain" rel="noopener noreferrer" target="_blank"><FaLinkedin></FaLinkedin></a></li>
                        <li><a href="https://github.com/neon64" target="_blank" rel="noopener noreferrer"><FaGithub></FaGithub></a></li>
                        <li><a href="https://soundcloud.com/chris-chamberlain-music" target="_blank" rel="noopener noreferrer"><FaSoundcloud></FaSoundcloud></a></li>
                    </SocialButtons>
                    > Please check out my new <Link to="/cv" css={animatedBlink}>online CV</Link><br /><br />
                    > Todo: write blog posts</pre>
                </noscript> */}
            {/* <TmuxStatus>
                <pre>localhost 16.3.0</pre>
                <pre>1:fish*</pre>
                <pre>{ this.state.time.toISOString() }</pre>
            </TmuxStatus> */}
            </TerminalCenter>
            <Footer darkMode={true} floatBottom={true} />
        </Terminal>
        </>
    )
    }